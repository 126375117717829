
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './tailwind.css'
import VueApexCharts from 'vue-apexcharts'
import VueToastr from "vue-toastr";
import axios from 'axios'
import VueAxios from 'vue-axios'
import vueNumeralFilterInstaller from "vue-numeral-filter";
import VueFilterDateFormat from "vue-filter-date-format";




Vue.use(VueAxios, axios)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
});
Vue.use(vueNumeralFilterInstaller);
Vue.use(VueFilterDateFormat);
Vue.use(require("vue-moment"));


window.axios=axios
axios.defaults.baseURL = 'https://api.insucare.ng';
// axios.defaults.baseURL = 'http://localhost:8000';
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.jwt


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
