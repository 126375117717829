<template>
  <div class="main-content relative">
    <SideBar />

    <div class="page-view bg-slate-100">
      <section class="flex justify-between w-full h-20 items-center shadow-xl">
        <div>
          <h2 class="text-2xl content-spacer">Add Facility</h2>
        </div>

        <div class="mr-10">
          <select name="" class="py-3 rounded">
            <option class="py-2">{{ $store.state.user.firstname }}</option>
            <option class="py-6">Settings</option>
          </select>
        </div>
      </section>

      <div class="flex content-spacer p-4">
        <div class="card mx-auto w-[70%]">
          <div class="">
            <div class="flex flex-col space-y-3">
              <div class="form-row">
                <input
                  type="text"
                  class="form-control auth-form"
                  placeholder="Facility Name"
                  v-model="form_data.name"
                />
              </div>

           

              <div class="form-row">
                <input
                  type="text"
                  class="form-control auth-form"
                  placeholder="Phone Number"
                  v-model="form_data.phone_number"
                />
              </div>

              <div class="form-row">
                <input
                  type="text"
                  class="form-control auth-form"
                  placeholder="E-mail"
                  v-model="form_data.email"
                />
              </div>

              <div class="form-row">
                <input
                  type="text"
                  class="form-control auth-form"
                  placeholder="Facility Address"
                  v-model="form_data.address"
                />
              </div>

              <div class="form-row">
                <button
                  class="bg-primary px-6 rounded-lg py-4 text-white font-bold w-[90%] md:w-[100%] mt-8"
                  @click="addFacility"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    
    
    <script>
import SideBar from "@/views/SideBar.vue";

export default {
  components: {
    SideBar,
  },
  data() {
    return {
      form_data: {
       care_level:"Secondary",
       is_government_owned:false,
      },
    };
  },
  beforeMount() {},
  methods: {
    addFacility() {
      this.axios
        .post(`/api/facilities`, this.form_data)
        .then((response) => {
          console.log(response);
          this.settings = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {},
};
</script>
    
    
    <style scoped>
.form-row {
  @apply my-2;
}

.auth-form {
  @apply placeholder:text-black w-[100%]  md:w-[100%] border border-gray-300;
}
</style>