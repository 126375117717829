<template>
  <div class="main-content relative">
    <SideBar />

    <div class="page-view bg-slate-100">
      <section class="flex justify-between w-full h-20 items-center shadow-xl">
        <div>
          <h2 class="text-2xl content-spacer">Manage Staff</h2>
        </div>

        <div class="mr-10">
          <select name="" class="py-3 rounded">
            <option class="py-2">{{ $store.state.user.firstname }}</option>
            <option class="py-6">Settings</option>
          </select>
        </div>
      </section>

      <div class="page-container">
        <div class="form-card">
          <div class="">
            <div class="mb-6">
              <p class="balance-subtitle"></p>
            </div>
            <form action="" @submit.prevent="addForm">
              <div class="form-row">
                <input
                  type="text"
                  required
                  class="form-control auth-form"
                  placeholder="First Name"
                  v-model="auth_user.firstname"
                />

                <input
                  type="text"
                  required
                  class="form-control auth-form"
                  placeholder="Last Name"
                  v-model="auth_user.lastname"
                />
              </div>

              <div class="form-row">
                <input
                  required
                  type="email"
                  class="form-control auth-form"
                  placeholder="Email"
                  v-model="auth_user.email"
                />

                <input
                  required
                  type="text"
                  class="form-control auth-form"
                  placeholder="Phone Number"
                  v-model="auth_user.phone_number"
                />
              </div>

              <div class="form-row">
                <select
                  required
                  v-model="auth_user.is_admin"
                  class="form-control auth-form"
                >
                  <option class="py-3">User Type</option>
                  <option
                    class="py-3"
                    v-for="freq in types"
                    :key="freq"
                    :value="freq.value"
                  >
                    {{ freq.name }}
                  </option>
                </select>
              </div>

              <div class="form-row">
                <input
                  required
                  type="password"
                  class="form-control auth-form"
                  placeholder="Password"
                  v-model="auth_user.password"
                />
              </div>

              <div class="form-row">
                <button
                  class="bg-primary px-6 rounded-lg py-3 text-white font-bold w-full mt-8"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        <div class="list-card">
          <div class="">
            <div class="mb-6">
              <p class="balance-amount">Staff</p>
            </div>

            <div class="">
              <table
                class="w-full text-left border border-separate rounded border-slate-200"
                cellspacing="0"
              >
                <tbody>
                  <tr class="bg-gray-100">
                    <th class="table-header">Name</th>

                    <th class="table-header">Email</th>
                    <th class="table-header">Phone</th>
                    <th class="table-header">Role</th>
                  </tr>

                  <tr class="" v-for="user in users" :key="user.id">
                    <td class="table-td">
                      <!-- <div class="flex flex-row"> -->
                      {{ user.full_name }}

                      <!-- </div> -->
                    </td>
                    <td class="table-td">
                      <div class="">
                        <span> {{ user.email }}</span>
                      </div>
                    </td>
                    <td class="table-td">
                      {{ user.phone_number }}
                    </td>

                    <td class="table-td">
                      <button class="btn-sm" v-if="user.is_admin == true">
                        admin
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- End Responsive Table -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    
    
    <script>
// import plansJson from "./plans.json";
import SideBar from "@/views/SideBar.vue";

export default {
  components: {
    SideBar,
  },
  data() {
    return {
      auth_user: {
        is_admin: false,
      },
      types: [
        {
          name: "Regular",
          value: true,
        },
        {
          name: "Admin",
          value: false,
        },
      ],
      users: [],
      response: "",
      okay: false,
    };
  },
  beforeMount() {},
  methods: {
    addForm() {
      this.axios
        .post(`/api/auth/register/staff`, {
          firstname: this.auth_user.firstname,
          lastname: this.auth_user.lastname,
          email: this.auth_user.email,
          phone_number: this.auth_user.phone_number,
          password: this.auth_user.password,
          is_admin: this.auth_user.is_admin,
          hmo_id: this.$store.state.user.hmo.id,
        })
        .then((response) => {
          console.log(response);
          this.$toastr.s("Added Successfully!");
        })
        .catch((error) => {
          console.error(error);
          this.$toastr.e("Error!");
        });
    },

    getPlans() {
      this.axios
        .get(`/api/staff/hmo/${this.$store.state.user.hmo.id}`)
        .then((response) => {
          console.log(response);
          this.users = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.getPlans();
  },
};
</script>
        
<style scoped>
.page-container {
  @apply flex flex-col md:flex-row content-spacer p-4 space-y-3 md:space-x-3;
}
.form-card {
  @apply card w-full md:w-1/3;
}
.list-card {
  @apply card w-full md:w-2/3;
}
.form-row {
  @apply my-2;
}

.auth-form {
  @apply placeholder:text-black w-[100%]  md:w-[100%] border border-gray-300;
}
.balance-amount {
  @apply font-bold text-3xl;
}
</style>