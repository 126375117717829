<template>
  <nav class="flex-col sidebar">
    <div class="flex ml-8 space-x-3 h-20 items-center">
      <img src="/logo.png" class="w-12 h-12" />
      <span class="text-2xl">Insucare</span>
    </div>

   <div class="flex flex-col h-[90%] justify-between p-4">
    <ul class="text-left ml-8 space-y-1 text-base">
      
      
      <li class="flex cursor-pointer" v-for="link in links" :key="link">
    
        <router-link :to="`/${link.url}`" class="nav-item">
          <i :class="`${link.icon}`"></i>
          <span class="ml-4 text-sm">{{ link.name }}</span>
        </router-link>
      </li>
    
    </ul>

    <div
      class="bottom-6 right-0 left-0 py-4 flex flex-col space-y-2 bg-gray-100 cursor-pointer rounded-md text-center"
     
    >
    <img src="/unknown_person.jpg" class="h-16 w-16 rounded-full mx-auto -mt-10" alt="">
      <span class="">
       {{ $store.state.user.full_name}} 
      </span>

      <span class="font-thin"> {{ $store.state.user.user_role}}</span>

      <div class="flex space-x-3  justify-center">
        <i class="las la-cog la-2x bottom-icon" @click="gotoSettings"></i>
        <i class="las la-sign-in-alt la-2x bottom-icon"
        @click="logOut"
        ></i>

      </div>
     
    </div>
   </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      links:[
        {
          name: "Overview",
          icon: "las la-home la-2x",
          url: "overview",
        },
        {
          name: "Customers",
          icon: "las la-users la-2x",
          url: "customers",
        },
        {
          name: "Facilities",
          icon: "las la-hospital la-2x",
          url: "facilities",
        },
        {
          name: "Transactions",
          icon: "las la-credit-card la-2x",
          url: "wallet",
        },
        {
          name: "Staff",
          icon: "las la-user-tie la-2x",
          url: "manage-staff",
        }
      ]
    };
  },
  methods: {
    logOut() {
      this.$router.push("/");
    },
    gotoSettings() {
      this.$router.push("/org-settings");
    },
  },
};
</script>

<style scoped>
.bottom-icon{
  @apply  p-2 bg-white rounded-full;
}

.nav-item {
  @apply w-[90%] flex items-center pl-2 py-2 ;
}
</style>