<template>
  <div class="flex flex-row">
    <!-- <div
      class="w-1/2 h-screen bg-no-repeat bg-cover"
      style="background-image: url('/login_pic.jpg')"
    ></div> -->

    <img src="/reg_3.jpg" class="hidden md:w-1/2 md:block rounded-r h-screen" />

    <div class="flex w-full text-center justify-center items-center h-screen bg-slate-100">
      <div class="mx-auto w-[80%]">
        <div class="mb-6">
          <img src="/logo.png" class="w-16 h-16 mx-auto mb-2" />


          <h2 class="text-3xl font-bold leading-5">Log In</h2>
          <p class="balance-subtitle"></p>
        </div>
        <div class="flex flex-col space-y-3">
          <div class="form-row">
            <input
              type="email"
              class="form-control auth-form"
              placeholder="Email"
              v-model="auth_user.email"
            />
          </div>

          <div class="form-row">
            <input
              type="password"
              class="form-control auth-form"
              placeholder="Password"
              v-model="auth_user.password"
            />
          </div>

          <!-- <div class="form-row border">
            <div class="auth-form text-center">
              <p class="text-primary">Forgot your Password?</p>
            </div>
          </div> -->

          <div class="form-row">
            <button
              class="bg-primary px-6 rounded-lg py-3 text-white font-bold w-[90%] md:w-[60%] mt-8"
              @click="loginUser"
            >
              Continue
            </button>
          </div>

          <div class="form-row">
            <div class="flex space-x-3 justify-center">
              <div class="">
                <p class="text-lg">Don't have an account?</p>
              </div>
              <router-link to="/register">
                <p class="text-secondary text-lg">Register</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>



<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";


export default {
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      auth_user: {
        password: "",
        email: "",
      },
      response: "",
      okay: false,
    };
  },
  beforeMount() {},
  methods: {
    loginUser() {
      this.isLoading = true;
      this.axios
        .post(`/api/auth/login/staff`, {
          email: this.auth_user.email,
          password: this.auth_user.password,
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s('Welcome Back!');
          this.$store.dispatch("updateUser", response.data.staff)
          this.$store.dispatch("updateToken", response.data.token)
          // localStorage.setItem('jwt', response.data.token )
          // localStorage.setItem('user', JSON.stringify(response.data.staff) )
          this.$router.push("/overview");

        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);

        });
    },
  },
  created() {},
};
</script>


<style scoped>
.form-row {
  @apply my-2  basis-4/5;
}

.auth-form {
  @apply placeholder:text-black w-[90%]  md:w-[60%] border border-gray-300;
}
</style>