<template>
  <div class="main-content relative">
    <SideBar />

    <div class="page-view">
      <section class="flex justify-between w-full h-20 items-center shadow-xl">
        <div>
          <h2 class="text-2xl content-spacer">Add Customer</h2>
        </div>

        <div class="mr-10">
          <select name="" class="py-3 rounded">
            <option class="py-2">{{ $store.state.user.firstname }}</option>
            <option class="py-6">Settings</option>
          </select>
        </div>
      </section>

      <div class="flex content-spacer p-4">
        <div class="card mx-auto w-[80%]">
          <div class="">
            <form @submit.prevent="addForm">
              <div class="flex flex-col space-y-3">
                <div class="form-row">
                  <input
                    type="text"
                    class="form-control auth-form"
                    placeholder="Last Name"
                    v-model="form_data.lastname"
                  />

                  <input
                    type="text"
                    class="form-control auth-form"
                    placeholder="First Name"
                    v-model="form_data.firstname"
                  />

                  <input
                    type="text"
                    class="form-control auth-form"
                    placeholder="Other Names"
                    v-model="form_data.other_names"
                  />
                </div>

                <div class="form-row">
                  <input
                    required
                    type="text"
                    pattern="\d*"
                    maxlength="11"
                    class="form-control auth-form"
                    placeholder="Phone Number"
                    v-model="form_data.phone_number"
                  />

                  <input
                    type="date"
                    class="form-control auth-form"
                    placeholder="Date of Birth"
                    v-model="form_data.dob"
                  />

                  <input
                    type="text"
                    class="form-control auth-form"
                    placeholder="E-mail Address"
                    v-model="form_data.email"
                  />
                </div>

                <div class="form-row">
                  <select
                    required
                    class="form-control auth-form"
                    v-model="form_data.gender"
                  >
                    <option class="py-3">Select Gender</option>
                    <option class="py-3">Male</option>
                    <option class="py-3">Female</option>
                  </select>

                  <select
                    class="form-control auth-form"
                    v-model="form_data.facility_id"
                  >
                    <option value="" disabled selected>
                      Select Health Facility
                    </option>
                    <option
                      v-for="facility in facilities"
                      :key="facility.id"
                      :value="facility.id"
                    >
                      {{ facility.name }}
                    </option>
                  </select>

                  <input
                    type="text"
                    class="form-control auth-form"
                    placeholder="Agency ID"
                    v-model="form_data.hmo_number"
                  />

                  <select
                    required
                    class="form-control auth-form"
                    v-model="form_data.plan_id"
                  >
                    <option value="" disabled selected>Select Plan</option>

                    <option
                      v-for="plan in plans.data"
                      :key="plan.id"
                      :value="plan.id"
                    >
                      {{ plan.name }}({{ plan.frequency }})
                    </option>
                  </select>
                </div>

                <div class="form-row">
                  <input
                    type="text"

                    class="form-control auth-form"
                    placeholder="NIN"
                    v-model="form_data.nin"
                  />

                  <input
                    type="text"
                    required
                    v-model="form_data.address"
                    class="form-control auth-form"
                    placeholder="Full Home Address"
                  />
                </div>

                <div class="flex flex-row items-center justify-between">
                  <!-- <div>
                  <input
                    type="checkbox"
                    class="p-2 rounded-md"
                    id=""
                    v-model="form_data.send_sms"
                  />
                  <label class="text-primary">Send SMS to Customer </label>
               
                 </div> -->

                  <div>
                    <input
                      type="checkbox"
                      class="p-2 rounded-md"
                      id=""
                      v-model="form_data.sync_user"
                    />
                    <label class="text-primary">Sync User</label>
                  </div>
                </div>

                <div class="form-row">
                  <button
                    type="submit"
                    class="bg-primary px-6 rounded-lg py-4 text-white font-bold w-[90%] md:w-[100%] mt-8"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
       
<script>
import SideBar from "@/views/SideBar.vue";

export default {
  components: {
    SideBar,
  },
  data() {
    return {
      form_data: {
        gender: "Select Gender",
        facility_id: "",
        nin: "",
        plan_id: "",
        send_sms: false,
        subscribe_user: false,
        plan_id: "",
        // days: "",
      },
      facilities: "",
      plans: "",
      okay: false,
    };
  },
  beforeMount() {},
  methods: {
    addForm() {
      this.form_data.hmo_id = this.$store.state.user.hmo.id;
      // this.form_data.days = this.form_data.plan_id.days;
      // this.form_data.plan_id = this.form_data.plan_id.id;
      this.axios
        .post(`/api/auth/register/user`, this.form_data)
        .then((response) => {
          console.log(response);
          let user = response.data.data;
          this.$router.push(`/customer/${user.id}`);

          this.$toastr.s("Customer Added Successsfully!");
          // if (this.form_data.send_sms == true) {
          //   this.sendSMS();
          // }
          this.form_data = {};
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFacilities() {
      this.axios
        .get(`/api/facilities`)
        .then((response) => {
          console.log(response);
          this.facilities = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getPlans() {
      this.axios
        .get(`/api/plans/hmo/${this.$store.state.user.hmo_id}`)
        .then((response) => {
          console.log(response);
          this.plans = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sendSMS() {
      let message = `You have been Successfully enrolled to the ${this.$store.state.user.hmo.name}. Click Link to Purchase your Plan.`;
      this.axios
        .post(
          `https://api.bulksmslive.com/v2/app/sms?email=faisalnas7@gmail.com&password=skrull123&sender_name=INSUCARE&message=${message}&recipients=${this.form_data.phone_number}`,
          {}
        )
        .then((response) => {
          console.log(response);
          // let reply = response.data.msg;
          console.log("Message Sent");
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  created() {
    this.getFacilities();
    this.getPlans();
  },
};
</script>
    
    
    <style scoped>
.form-row {
  @apply my-2 flex flex-row space-x-4;
}

.auth-form {
  @apply placeholder:text-black w-full  md:w-full border border-gray-300;
}
</style>