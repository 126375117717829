import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: {
    user: {
      hmo: {},
      firstname: "User",
      lastname: "",
      email: "",
      phone_number: "",
    },
    jwt: "",
    loader: {
      isLoading: true,
      fullPage: true,
      color: "#8c33e4",
    },
    plans: [],
  },
  getters: {},
  mutations: {
    START_LOADER(state) {
      state.loader.isLoading = true;
    },

    END_LOADER(state) {
      state.loader.isLoading = false;
    },
    UPDATE_USER(state, userObj) {
      state.user = userObj;
    },

    UPDATE_TOKEN(state, payload) {
      state.jwt = payload;
    },
  },
  actions: {
    startLoader(context) {
      context.commit("START_LOADER");
    },

    endLoader(context) {
      context.commit("END_LOADER");
    },

    addToPlans(context, plansArray) {
      context.commit("UPDATE_PLANS", plansArray);
    },

    updateUser(context, userObj) {
      context.commit("UPDATE_USER", userObj);
    },
    updateToken(context, token) {
      context.commit("UPDATE_TOKEN", token);
    },
  },
  modules: {},
});
