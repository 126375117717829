<template>
  <div class="main-content">
    <SideBar />
    <div class="page-view">
      <HeaderView />

      <section class="content-spacer">
        <div class="">
          <p class="text-2xl font-thin">
            Welcome back {{ $store.state.user.firstname }}
          </p>
          <p>Here’s a summary of what’s happening in Insucare today.</p>
        </div>

        <div class="bg-primaryFaint w-full md:w-4/5 rounded">
          <div class="py-8 px-8">
            <p class="font-bold text-xl">{{ $store.state.user.hmo.name }}</p>
            <p>Create Plans so users can subscribe to them.</p>

            <router-link to="/manage-plans">
              <button
                class="bg-primary rounded py-2 px-6 shadow-xl text-white mb-10 mt-5"
              >
                Manage Plans
                <!--   @click="openModal" -->
              </button>
            </router-link>
          </div>
        </div>
      </section>

      <div
        class="flex flex-col md:flex-row content-spacer mt-6 space-y-4 md:space-x-1 md:divide-x-2"
      >
        <div class="basis-1/3">
          <p class="balance-title">Total Customers</p>
          <p class="balance-amount">{{ dashboard.total_customers }}</p>
          <p class="balance-subtitle">customers subscribed to your plan</p>
        </div>

        <div class="md:pl-10 basis-1/3">
          <p class="balance-title">Total Revenue</p>
          <p class="balance-amount">
            &#x20A6;{{ dashboard.total_revenue | numeral(0, 0) }}
          </p>
          <p class="balance-subtitle">current Funds in wallet</p>
        </div>

        <div class="md:pl-10 basis-1/3">
          <p class="balance-title">Revenue this Month</p>
          <p class="balance-amount">
            &#x20A6; {{ dashboard.current_month_revenue | numeral(0, 0) }}
          </p>
          <p class="balance-subtitle">current Funds in wallet</p>
        </div>
      </div>

      <div class="grid grid-cols-6">
        <div class="col-span-6 md:col-span-4">
          <div class="card content-spacer">
            <h2>Month on Month Savings</h2>
            <p>October</p>
            <div class="flex">
              <button class="bg-primary w-1/2 py-3 rounded text-white">
                0.00 Spent
              </button>
              <button class="bg-primaryFaint w-1/2 py-3 rounded text-black">
                0.00 Saved
              </button>
            </div>
          </div>

          <!-- Chart Card -->
          <div class="card content-spacer">
            <apexchart
              type="bar"
              :options="options"
              :series="series"
            ></apexchart>
          </div>

          <!-- End of Chart -->
        </div>

        <div class="flex-col card hidden md:block">
          <h2 class="balance-title mb-5">Quick Links</h2>
          <ul class="flex flex-col space-y-5 text-primary">
            <li class="flex justify-between">
              <span class="">Add Plan</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </li>
            <li class="flex justify-between">
              Add Staff
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </li>
            <li class="flex justify-between">
              Update Profile

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </li>
          </ul>
        </div>
      </div>
      <BottomNav />

      <!-- <FooterView /> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SideBar from "@/views/SideBar.vue";
import HeaderView from "@/views/HeaderView.vue";
import BottomNav from "@/views/BottomNav.vue";
// import FooterView from "@/views/FooterView.vue";
// import SaveMoneyModal from "@/views/components/SaveMoneyModal.vue";

export default {
  name: "HomeView",
  components: {
    SideBar,
    // FooterView,
    HeaderView,
    BottomNav,
  },
  data() {
    return {
      dashboard: "",
      options: {
        chart: {
          id: "my-savings",
        },
        xaxis: {
          categories: [
            "January",
            "February",
            "March",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
        },
      },
      series: [
        {
          name: "Savings",
          data: [
            6000, 1200, 4000, 20000, 6700, 8000, 7000, 1000, 4500, 3500, 54000,
          ],
        },
      ],
    };
  },
  mounted() {
    if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      location.reload();
    }
  },
  methods: {
    userDashboard() {
      this.axios
        .get(`/api/dashboard/staff`)
        .then((response) => {
          console.log(response);
          this.dashboard = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    openModal() {
      this.$refs.SaveMoneyModal.setValue(true);
    },
  },
  created() {
    this.userDashboard();
  },
};
</script>
<style scoped>
.balance-title {
  @apply text-primary text-xl font-extralight;
}
.balance-amount {
  @apply font-bold text-3xl;
}
.balance-subtitle {
  @apply font-extralight text-gray-600;
}
</style>
