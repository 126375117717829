<template>
  <div class="main-content">
    <SideBar />

    <div class="page-view">
      <HeaderView />
     

      <div class="content-spacer flex space-x-2 flex-col md:flex-row">
        <div class="card w-full md:w-2/3">
          <h2 class="mb-2">Facilities</h2>

          <div
            class="flex flex-col md:flex-row justify-between space-y-4 md:space-x-1 mt-6"
          >
            <div class="bg-violet-200 px-8 py-4 rounded-lg">
              <p class="text-xl text-primary font-bold text-center">{{ facilities.length }} </p>
              <p class="balance-subtitle text-primary text-center">
                All Facilities
              </p>
            </div>
            <div class="bg-emerald-200 px-8 py-4 rounded-lg">
              <p class="text-xl text-emerald-700 font-bold text-center">{{ facilities.length }}  </p>
              <p class="balance-subtitle text-center text-emerald-700">
                Approved Facilities
              </p>
            </div>
            <div class="bg-yellow-200 px-8 py-4 rounded-lg">
              <p class="text-xl font-bold text-center text-yellow-700">0</p>
              <p class="balance-subtitle text-center text-yellow-700">
                Rejected Facilities
              </p>
            </div>
          </div>
        </div>

        <div class="card w-full md:w-1/3">
          <p>New Facility</p>
          <div class="mt-12">
            <router-link to="/add-facility">
              <button class="bg-primary text-white py-3 px-8 rounded-lg">
                <i class="las la-user-plus"></i> New Facility
              </button>
            </router-link>
          </div>
        </div>
      </div>

      <div class="content-spacer card">
        <h2 class="">Facilities</h2>
        <div class="flex mt-4 space-x-6">
          <span
            class="text-primary py-2 px-4 rounded bg-primaryFaint cursor-pointer"
            >All</span
          >
          <span class="py-2 px-4">Active</span>
          <span class="py-2 px-4">Inactive</span>
        </div>

        <div class="w-full mt-6">
          <AllFacilitiesList :facilities="facilities" />
        </div>
      </div>
    </div>
    <BottomNav />
  </div>
</template>
    
    <script>
import SideBar from "@/views/SideBar.vue";
import TransactionsView from "./components/TransactionsView.vue";
import BottomNav from "@/views/BottomNav.vue";
import HeaderView from "@/views/HeaderView.vue";
import AllFacilitiesList from "./components/AllFacilitiesList.vue";

export default {
  components: {
    SideBar,
    TransactionsView,
    BottomNav,
    HeaderView,
    AllFacilitiesList,
  },
  data() {
    return {
      facilities: [],
    };
  },
  methods: {
//
  },
  created() {
    this.getFacilities();
  },
};
</script>
    
    <style scoped>
h2 {
  @apply font-bold text-2xl;
}

.balance-subtitle {
  @apply font-extralight;
}
</style>
    