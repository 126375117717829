<template>
  <div class="bg-gray-100">
    <div class="w-full bg-slate-100">
      <section
        class="flex flex-row space-x-2 justify-center w-full h-20 items-center shadow-xl"
      >
        <div>
          <i class="las la-times-circle la-3x"></i>
        </div>
        <div class="">
          <span class="text-2xl"> Transaction Failed</span>
        </div>
      </section>

      <div
        class="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-x-6"
      >
        <div class="card mt-8 w-[90%] md:w-[40%]" id="printDiv" ref="printNow">
          <div class="">
            <p class="balance-title text-center my-12">
              We are Sorry we could not complete your transaction
            </p>

            <hr>

            <button class="btn my-6 w-full" 
            @click="$router.go(-1)"
            >
                <i class="las la-sync-alt"></i>
            Retry 
          </button>
          </div>
        </div>
        <!-- End of Card -->

        <div class="">
          <p>
            For enquiries Contact us at
            <a href="tel:+08023493849" class="text-primary font-semibold"
              >08023493849</a
            >
          </p>
          <p>
            or
            <a
              href="mailto:support@insucare.ng"
              class="text-primary font-semibold"
              >support@insucare.ng</a
            >
            the Following Address
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
        
        
        
        <script>
import paystack from "vue-paystack";
// import SideBar from "@/views/SideBar.vue";

export default {
  components: {
    // SideBar,
    paystack,
  },
  data() {
    return {
      paystackkey: "pk_test_6555651ecaf3d32f6c6a49dd81f875f06be5f332", //paystack public key
      email: "faisalnas7@gmail.com",
      transaction: "",
      plans: [],
      response: "",
      hmo: "",
    };
  },
  computed: {},
  beforeMount() {},
  methods: {
    submitForm() {
      this.$router.push("/overview");
    },
  },
  created() {
  },
};
</script>
        
   <style scoped>
.form-row {
  @apply my-2;
}

.auth-form {
  @apply placeholder:text-slate-500 w-[100%]  md:w-[100%] border border-gray-300;
}

.auth-form-new {
  @apply placeholder:text-slate-500 w-full  md:w-1/2 border border-gray-300;
}

.balance-title {
  @apply text-primary  font-medium h-10;
}

.balance-subtitle {
  @apply font-light text-gray-600 h-10;
}
</style>