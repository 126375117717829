<template>
  <div class="">
    <table
      class="w-full text-left border border-separate rounded border-slate-200"
      cellspacing="0"
    >
      <tbody>
        <tr>
          <th scope="col" class="table-header">Full Name</th>
          <th scope="col" class="table-header">Phone Number</th>
          <th scope="col" class="table-header">Facility</th>
          <th scope="col" class="table-header">Subscription</th>
          <th scope="col" class="table-header">Status</th>
          <th scope="col" class="table-header">Action</th>
        </tr>

        <tr class="table-row" v-for="customer in customers" :key="customer.id">
          <td data-th="Name" class="table-td">{{ customer.full_name }}</td>
          <td data-th="Title" class="table-td">{{ customer.phone_number }}</td>
          <td data-th="Title" class="table-td">
            <span v-if="customer.facility != null">
              {{ customer.facility.name }}
            </span>
          </td>
          <td data-th="Title" class="table-td">
            <span v-if="customer.latest_subscription != null">
              {{ customer.latest_subscription.plan.name }}
            </span>
          </td>
          <td data-th="Company" class="table-td">
            <span v-if="customer.latest_subscription != null" :class="customer.latest_subscription.status == 'active' ? 'active-label' : 'inactive-label'">
              {{ customer.latest_subscription.status }}
            </span>
          </td>

          <td>
            <a :href="`/customer/${customer.id}`" class="label-btn mr-1">view</a>
            <!-- <a :href="`/edit-customer/${customer.id}`" class="status">edit</a> -->
          </td>
        </tr>
      </tbody>
    </table>
    <!-- End Responsive Table -->
  </div>
</template>

   <script>
export default {
  props: ["customers"],

  data() {
    return {};
  },
};
</script>

  <style>
.label-btn {
  @apply bg-primary text-white text-sm px-4 py-1 rounded-lg;
}
.table-header {
  @apply hidden
                h-12
                px-6
                text-sm
                font-medium
                border-l
                sm:table-cell
                first:border-l-0
                stroke-slate-700
                text-slate-700
                bg-slate-100;
}
.table-td {
  @apply before:w-24 before:inline-block before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] sm:before:content-none flex items-center sm:table-cell h-12 px-6 text-sm transition duration-300 sm:border-t sm:border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500;
}

.table-row {
  @apply block border-b  last:border-b-0 border-slate-200 sm:border-none;
}
</style>

 