<template>
    <div class="main-content">
      <SideBar class="sidebar-view" />
  
      <div class="page-view">
        <section class="flex justify-between w-full h-20 items-center shadow-xl">
          <div>
            <h2 class="text-2xl content-spacer">Customers</h2>
          </div>
  
          <div class="mr-10">
            <select name="" class="py-3 rounded">
              <option class="py-2">{{ $store.state.user.displayName }}</option>
              <option class="py-6">Settings</option>
            </select>
          </div>
        </section>
  
  
        <div class="content-spacer flex space-x-2 flex-col md:flex-row">
          <div class="card w-full md:w-2/3">
            <h2 class="mb-2">Tickets Summary</h2>
  
            <div
              class="flex flex-col md:flex-row justify-between space-y-4 md:space-x-1 mt-6"
            >
              <div class="bg-violet-200 px-8 py-4 rounded-lg">
                <p class="text-xl text-primary font-bold text-center"> {{ tickets.total }} </p>
                <p class="balance-subtitle text-primary text-center">
                  All Tickets
                </p>
              </div>
              <div class="bg-emerald-200 px-8 py-4 rounded-lg">
                <p class="text-xl text-emerald-700 font-bold text-center"> {{ tickets.total }} </p>
                <p class="balance-subtitle text-center text-emerald-700">
                  Active Tickets
                </p>
              </div>
              <div class="bg-yellow-200 px-8 py-4 rounded-lg">
                <p class="text-xl font-bold text-center text-yellow-700">0</p>
                <p class="balance-subtitle text-center text-yellow-700">
                  Closed Tickets
                </p>
              </div>
            </div>
          </div>
  
          <div class="card w-full md:w-1/3">
            <p> New Ticket</p>
            <div class="mt-12">
              <router-link to="/add-ticket">
  
              <button class="bg-primary text-white py-3 px-8 rounded-lg">
                  <i class="las la-ticket-alt"></i> New Ticket
              </button>
          </router-link>
  
            </div>
          </div>
        </div>
  
        <div class="content-spacer card">
          <h2 class="">Tickets   </h2>
          <div class="flex mt-4 space-x-6">
            <span
              class="text-primary py-2 px-4 rounded bg-primaryFaint cursor-pointer"
              >All</span
            >
            <span class="py-2 px-4">Active</span>
            <span class="py-2 px-4">Closed</span>
          </div>
  
          <div class="w-full mt-6">
            <AllTicketsList :tickets="tickets.data" />
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  </template>
    
    <script>
  import SideBar from "@/views/SideBar.vue";
  import TransactionsView from "./components/TransactionsView.vue";
  import BottomNav from "@/views/BottomNav.vue";
  import AllTicketsList from "./components/AllTicketsList.vue";
  
  export default {
    components: {
      SideBar,
      TransactionsView,
      BottomNav,
      AllTicketsList
  },
    data() {
      return {
        tickets: {
            total: 3,
            data:[
                {
                    customer:"Jude Daniel",
                    type:"Complaint",
                    title:"Facility Refused Me Access to Care",
                    status:"active"
                }
            ]

        },
      };
    },
    methods: {
      getCustomers() {
        this.axios
          .get(`/api/users/hmo/${this.$store.state.user.hmo_id}`)
          .then((response) => {
            console.log(response);
            this.customers = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
      },
    },
    created(){
      this.getCustomers()
    }
  };
  </script>
    
    <style scoped>
  h2 {
    @apply font-bold text-2xl;
  }
  
  .balance-subtitle {
    @apply font-extralight;
  }
  </style>
    