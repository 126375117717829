<template>
    <div class="">
  
      <div class="">
       
        <div class="mt-6">
          <div class="card  w-full">
            <div class="">
              <form @submit.prevent="addForm">
                <div class="flex flex-col space-y-3">
                  <div class="form-row">
                    <input
                      type="text"
                      class="form-control auth-form"
                      placeholder="Last Name"
                      v-model="form_data.lastname"
                    />
  
                    <input
                      type="text"
                      class="form-control auth-form"
                      placeholder="First Name"
                      v-model="form_data.firstname"
                    />
  
                    <input
                      type="text"
                      class="form-control auth-form"
                      placeholder="Other Names"
                      v-model="form_data.other_names"
                    />
                  </div>
  
                  <div class="form-row">
                    <input
                      required
                      type="text"
                      class="form-control auth-form"
                      placeholder="Phone Number"
                      v-model="form_data.phone_number"
                    />
  
                    <input
                      type="date"
                      class="form-control auth-form"
                      placeholder="Date of Birth"
                      v-model="form_data.dob"
                    />
  
                    <input
                      type="text"
                      class="form-control auth-form"
                      placeholder="E-mail Address"
                      v-model="form_data.email"
                    />
                  </div>
  
                  <div class="form-row">
                    <select
                      class="form-control auth-form"
                      v-model="form_data.gender"
                    >
                      <option class="py-3">Select Gender</option>
                      <option class="py-3">Male</option>
                      <option class="py-3">Female</option>
                    </select>
  
                    <select
                      placeholder="Select Health Facility"
                      class="form-control auth-form"
                      v-model="form_data.facility_id"
                    >
                      <option class="py-3">Select Facility</option>
  
                      <option
                        v-for="facility in facilities"
                        :key="facility.id"
                        :value="facility.id"
                      >
                        {{ facility.name }}
                      </option>
                    </select>
  
                    <input
                      type="text"
                      class="form-control auth-form"
                      placeholder="Agency Unique Number"
                      v-model="form_data.hmo_number"
                    />
                    <!-- <select
                    placeholder="Select Health Facility"
                    class="form-control auth-form"
                    v-model="form_data.plan_id"
                  >
                  <option class="py-3">Select Plan </option>
  
                    <option
                      v-for="plan in plans"
                      :key="plan.id"
                      :value="plan"
                    >
                      {{ plan.name }}({{ plan.frequency }})
                    </option>
                  </select> -->
                  
                  </div>
  
                  <div class="form-row">
                    <textarea
                      name=""
                      placeholder="Full Home Address"
                      id=""
                      class="form-control auth-form"
                      v-model="form_data.address"
                      rows="3"
                    ></textarea>
                  </div>
  
                  <div class="flex flex-row items-center justify-between">
                  
  
  
                   <div>
                    <input
                      type="checkbox"
                      class="p-2 rounded-md"
                      id=""
                      v-model="form_data.sync_user"
                    />
                    <label class="text-primary">Sync  User</label>
                 
                   </div>
                  </div>
  
                  <div class="form-row">
                    <button
                      type="submit"
                      class="bg-primary px-6 rounded-lg py-4 text-white font-bold w-[90%] md:w-[100%] mt-8"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
         
  <script>
  // import SideBar from "@/views/SideBar.vue";
  
  export default {
    // components: {
    //   SideBar,
    // },
    data() {
      return {
        users:"",
        form_data: {
          gender: "Select Gender",
          facility_id: "Select Facility",
          send_sms: false,
          subscribe_user: false,
          plan_id: "",
          days: "",
        },
        facilities: "",
        plans: "",
        okay: false,
      };
    },
    beforeMount() {

        this.axios
          .get(`/api/users/${this.$route.params.id}`)
          .then((response) => {
            console.log(response);
            this.form_data = response.data.data;
          })
          .catch((error) => {
            console.error(error);
          });
    },
    methods: {
      addForm() {
        // this.form_data.hmo_id = this.$store.state.user.hmo.id;
        // this.form_data.days = this.form_data.plan_id.days;
        // this.form_data.plan_id = this.form_data.plan_id.id;
        this.axios
          .put(`/api/users/${this.$route.params.id}`, this.form_data)
          .then((response) => {
            console.log(response);
            this.$toastr.s("Customer Updated Successsfully!");
           
          })
          .catch((error) => {
            console.error(error);
          });
      },
      getFacilities() {
        this.axios
          .get(`/api/facilities`)
          .then((response) => {
            console.log(response);
            this.facilities = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
      },
  
      getPlans() {
        this.axios
          .get(`/api/plans/hmo/${this.$store.state.user.hmo_id}`)
          .then((response) => {
            console.log(response);
            this.plans = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
      },
      sendSMS() {
        let message = `You have been Successfully enrolled to the ${this.$store.state.user.hmo.name}. Click Link to Purchase your Plan.`;
        this.axios
          .post(
            `https://api.bulksmslive.com/v2/app/sms?email=faisalnas7@gmail.com&password=skrull123&sender_name=INSUCARE&message=${message}&recipients=${this.form_data.phone_number}`,
            {}
          )
          .then((response) => {
            console.log(response);
            // let reply = response.data.msg;
            console.log("Message Sent");
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
    },
    created() {
      this.getFacilities();
      this.getPlans();
    },
  };
  </script>
      
      
      <style scoped>
  .form-row {
    @apply my-2 flex flex-row space-x-4;
  }
  
  .auth-form {
    @apply placeholder:text-black w-full  md:w-full border border-gray-300;
  }
  </style>