<template>
  <ul class="bottom-nav">
    <li>
      <div class="flex justify-center space-y-2">
        <i class="las la-home"></i>
      </div>
      <router-link to="/overview">
        <span>Overview</span>
      </router-link>
    </li>
    <li>
      <div class="flex justify-center">
        <i class="las la-credit-card"></i>
      </div>
      <router-link to="/wallet">
        <span>Wallet</span>
      </router-link>
    </li>
    <li>
      <div class="flex justify-center">
        <i class="las la-user-tie"></i>
      </div>

      <router-link to="/manage-staff">
        <span>Staff</span>
      </router-link>
    </li>

    <li>
      <div class="flex justify-center">
        <i class="las la-cog"></i>
      </div>

      <router-link to="/org-settings">
        <span>Settings</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          name: "Home",
        },
      ],
    };
  },
};
</script>


<style scoped>
.bottom-nav {
  @apply flex fixed inset-x-0 bottom-0 bg-white w-full  lg:hidden text-primary justify-around;
}
li {
  @apply flex flex-col px-2 py-2;
}

ul a.router-link-exact-active {
  @apply text-primary border-b-2 border-primary;
}
</style>
