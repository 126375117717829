<template>
  <div>
    <div
      class="flex flex-row justify-between items-center bg-gray-200 p-3 mt-4 rounded-md"
      v-if="user.latest_subscription != null"
    >
      <div class="">
        <p class="font-light">Plan Name</p>
        <p class="text-md font-medium mt-3">
          {{ user.latest_subscription.plan.name }}
        </p>
      </div>

      <div class="">
        <p class="font-light">Plan Amount</p>
        <p class="text-md font-medium mt-3 mr-2">
          ₦{{ user.latest_subscription.plan.amount | numeral(0, 0) }}
          <i class="las la-copy text-primary"></i>
        </p>
      </div>

      <div class="">
        <p class="font-light">Expiry Date</p>
        <p class="text-md font-medium mt-3">
          {{ user.latest_subscription.expired_at | moment("D MMM Y") }}
        </p>
      </div>
    </div>

    <div class="flex flex-row justify-between my-5">
      <p class="font-light text-xl">Transactions</p>

      <p class="text-primary font-light" role="button">Export to PDF</p>
    </div>

    <div class="">
      <table
        class="w-full text-left border border-separate rounded border-slate-200"
        cellspacing="0"
      >
        <tbody>
          <tr class="bg-gray-100">
            <th class="table-header">ID</th>
            <th class="table-header">Amount</th>
            <th class="table-header">Plan</th>
            <th class="table-header">Date</th>
            <th class="table-header">Status</th>
          </tr>

          <tr class="" v-for="customer in transactions" :key="customer.id">
            <td class="table-td">
              <span class="balance-subtitle">#{{ customer.reference }}</span>
            </td>
            <td class="table-td">
              <div class="">
                <span> N{{ customer.amount | numeral(0, 0) }}</span>
              </div>
            </td>
            <td class="table-td">
              {{ customer.subscription.plan.name }}
            </td>
            <td class="table-td">
              {{ customer.created_at | moment("d MMM Y, HH:mm:ss") }}
            </td>

            <td class="table-td">
              <span class="active-label"> {{ customer.status }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- End Responsive Table -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: "",
      transactions: "",
    };
  },

  beforeMount() {
    this.axios
      .get(`/api/users/${this.$route.params.id}`)
      .then((response) => {
        console.log(response);
        this.user = response.data.data;
        this.transactions = response.data.data.transactions;
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>
<style scoped>
.balance-subtitle {
  @apply font-light text-blue-900 h-10;
}
</style>