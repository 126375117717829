<template>
    <div class="">
      <table
        class="w-full text-left border border-separate rounded border-slate-200"
        cellspacing="0"
      >
        <tbody>
          <tr>
            <th scope="col" class="table-header">Customer</th>
            <th scope="col" class="table-header">Category </th>
            <th scope="col" class="table-header">Title</th>
            <th scope="col" class="table-header">Status</th>
          </tr>
  
          <tr class="table-row" v-for="customer in tickets" :key="customer.id">
            <td data-th="Name" class="table-td">{{ customer.customer }}</td>
            <td data-th="Title" class="table-td">{{ customer.type }}</td>
            <td data-th="Title" class="table-td">
                {{ customer.status }}
            </td>
            <td data-th="Company" class="table-td">
  
  
             
                {{ customer.status }}
            </td>
          </tr>
        </tbody>
      </table>
      <!-- End Responsive Table -->
    </div>
  </template>
  
     <script>
  export default {
    props: ["tickets"],
  
    data() {
      return {};
    },
  };
  </script>
  
    <style>
  .status {
    @apply bg-black text-white px-5 py-2 rounded-lg;
  }
  .table-header {
    @apply hidden
                  h-12
                  px-6
                  text-sm
                  font-medium
                  border-l
                  sm:table-cell
                  first:border-l-0
                  stroke-slate-700
                  text-slate-700
                  bg-slate-100;
  }
  .table-td {
    @apply before:w-24 before:inline-block before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] sm:before:content-none flex items-center sm:table-cell h-12 px-6 text-sm transition duration-300 sm:border-t sm:border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500;
  }
  
  .table-row {
    @apply block border-b  last:border-b-0 border-slate-200 sm:border-none;
  }
  </style>
  
   