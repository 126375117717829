<template>
  <section class="flex justify-between w-full h-20 items-center bg-white px-16">
  
    
    <div class="flex flex-row items-center space-x-2 w-[55%] bg-gray-100 rounded-l-full rounded-r-full px-5">
        <i class="las la-search la-2x"></i>
    <input type="text" class="form-header" placeholder="Search Enrollees, Facilities, Transactions etc">
    </div>
    

    <div class="flex space-x-4">
      <i class="las la-bell la-2x side-icon" @click="gotoSettings"></i>
      <i class="las la-question-circle la-2x side-icon" @click="logOut"></i>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    logOut() {
      this.$router.push("/");
    },
    gotoSettings() {
      this.$router.push("/org-settings");
    },
  },
};
</script>

<style scoped>
.side-icon {
  @apply p-2 bg-gray-100 rounded-full;
}

.form-header{
      @apply py-1 border-none placeholder:font-extralight focus:border-none focus:ring-gray-100 my-2 w-full bg-gray-100 rounded-l-full rounded-r-full;
    }
</style>