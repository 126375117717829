<template>
  <div class="main-content relative">
    <SideBar />

    <div class="page-view bg-slate-100">
      <section class="flex justify-between w-full h-20 items-center shadow-xl">
        <div>
          <h2 class="text-2xl content-spacer">Add Ticket</h2>
        </div>

        <div class="mr-10">
          <select name="" class="py-3 rounded">
            <option class="py-2">{{ $store.state.user.firstname }}</option>
            <option class="py-6">Settings</option>
          </select>
        </div>
      </section>

      <div class="flex content-spacer p-4">
        <div class="card mx-auto w-[50%]">
          <div class="">
            <div class="mb-6">
              <p class="balance-subtitle"></p>
            </div>
            <div class="flex flex-col space-y-3">
              <div class="form-row">
                <input
                  type="email"
                  class="form-control auth-form"
                  placeholder="Ticket Title"
                  v-model="plan.name"
                />
              </div>

              <div class="form-row flex flex-col">
                <select
                  name=""
                  v-model="plan.frequency"
                  class="form-control form-select bg-white"
                >
                  <option class="py-3">Select Category</option>
                  <!-- <option class="py-3" v-for="freq in frequencies" :key="freq.id"
                    :value="freq"
                    >{{ freq.name }}</option> -->
                  <option class="py-3">General Inquiry</option>
                  <option class="py-3">Facility Service Complaint</option>
                  <option class="py-3">Payment Issue</option>
                  <option class="py-3">Subscription Issue</option>
                  <option class="py-3">Mobile Application Issue</option>
                </select>
              </div>

              <div class="form-row">
                <textarea
                  name=""
                  placeholder="Ticket Body"
                  id=""
                  class="form-control auth-form"
                  v-model="plan.description"
                  rows="5"
                ></textarea>
              </div>

              <div class="form-row">
                <button
                  class="bg-primary px-6 rounded-lg py-4 text-white font-bold w-[90%] md:w-[100%] mt-8"
                  @click="submitForm"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    
    
    <script>
// import plansJson from "./plans.json";
import SideBar from "@/views/SideBar.vue";

export default {
  components: {
    SideBar,
  },
  data() {
    return {
      plan: {
        frequency: "Select Frequency",
      },
      frequencies: [
        {
          name: "Daily",
          days: 1,
        },
        {
          name: "Weekly",
          days: 7,
        },
        {
          name: "Monthly",
          days: 30,
        },
        {
          name: "Yearly",
          days: 365,
        },
      ],
      plans: [],
      response: "",
      okay: false,
    };
  },
  beforeMount() {},
  methods: {
    addForm() {
      this.axios
        .post(`/api/ticket`, {
          hmo_id: this.$store.state.user.hmo.id,
          name: this.plan.name,
          amount: this.plan.amount,
          description: this.plan.description,
          max_persons: this.plan.max_persons,
          frequency: this.plan.frequency.name,
          days: this.plan.frequency.days,
        })
        .then((response) => {
          console.log(response);
          this.$toastr.s("Plan Added Successsfully!");
          this.plan = {};
        })
        .catch((error) => {
          console.error(error);
        });
    },
    submitForm() {
      this.$router.push("/overview");
    },
  },
  created() {},
};
</script>
    
    
    <style scoped>
.form-row {
  @apply my-2;
}

.auth-form {
  @apply placeholder:text-black w-[100%]  md:w-[100%] border border-gray-300;
}
</style>