<template>
  <div class="main-content">
    <SideBar />

    <div class="page-view">
      <HeaderView />

      <section class="content-spacer">
        <div class="flex flex-row justify-between items-center card">
          <div class="">
            <button
              class="border-2 border-primary bg-transparent px-2 py-1 rounded-full"
              @click="$router.go(-1)"
            >
              <i class="las la-arrow-left text-primary"></i>
            </button>

            <span class="ml-4 font-medium text-lg"> Customer Details</span>
          </div>
          <a
            :href="`/customer/${user.id}/edit`"
            class="bg-transparent border px-6 py-1 rounded-md text-primary"
          >
            <i class="las la-edit"></i>
            Edit
          </a>
        </div>

        <div
          class="flex flex-col md:flex-row space-y-3 md:space-x-3 justify-center mt-4"
        >
          <!-- User Details -->
          <div class="biodata">
            <div class="flex flex-row space-x-2">
              <!-- src="/images/Unknown_person.jpg" -->

              <!-- <img
                :src="`https://api-dev.one-farm.ng/image/${user.pic}`"
                onerror="this.onerror=null; this.src='/images/Unknown_person.jpg'"
                alt=""
                class="w-1/3 rounded-md"
              /> -->

              <div class="flex flex-col space-y-3 w-2/3">
                <p class="font-light text-2xl">{{ user.full_name }}</p>
                <p class="">{{ user.email }}</p>

                <div class="flex">
                  <!-- <button
                    class="bg-transparent border px-4 py-1 rounded-md text-primary"
                    @click="takePicAndroid"
                  >
                    <i class="las la-camera"></i>
                    Capture
                  </button> -->
                </div>
              </div>
            </div>

            <hr class="border my-7" />

            <div class="flex flex-row space-x-3">
              <div class="w-1/3">
                <p class="bio-key">Contact</p>
                <p class="bio-key">Gender</p>
                <p class="bio-key">Date of Birth</p>
                <p class="bio-key">NIN</p>
                <p class="bio-key">Agency ID</p>
                <p class="bio-key">Address</p>
              </div>

              <div class="w-2/3">
                <p class="bio-value">{{ user.phone_number }}</p>
                <p class="bio-value">{{ user.gender }}</p>
                <p class="bio-value">{{ user.dob | moment("D MMM, Y") }}</p>
                <p class="bio-value">{{ user.nimc_number }}</p>
                <p class="bio-value">{{ user.hmo_number }}</p>
                <p class="bio-value">{{ user.address }}</p>
              </div>
            </div>
          </div>

          <!-- Account Details -->
          <div class="bioaccounts">
            <ul class="flex flex-row space-x-6 pt-3 px-3 border-b">
              <li class="text-primary border-primary pb-2 border-b-2">
                <a :href="`/customer/${user.id}/transactions`">  Account </a>
              </li>
              <!-- <li>Dependents</li> -->
              <li>
                <a :href="`/customer/${user.id}/edit`">Edit</a>
              </li>
            </ul>

            <div
              class="flex flex-row justify-between items-center border p-3 mt-4 rounded-md"
            >
              <div class="">
                <p>Total Payment</p>
                <p class="text-2xl font-light mt-3">
                  ₦{{ user.transactions_sum_amount | numeral(0, 0) }}
                </p>
              </div>

              <div>
                <button class="bg-primary rounded-md py-2 px-4 text-white">
                  <i class="las la-arrow-alt-circle-up"></i>
                  <paystack
                    :amount="user.latest_subscription.plan.amount * 100"
                    :email="$store.state.user.email"
                    :subaccount="$store.state.user.hmo.sub_account_code"
                    :paystackkey="paystackkey"
                    :reference="reference"
                    :callback="callback"
                    :channels="channels"
                    :close="close"
                    :embed="false"
                  >
                    <span class="">Make Payment</span>
                  </paystack>
                </button>
              </div>
            </div>

            <router-view></router-view>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
         
  <script>
import SideBar from "@/views/SideBar.vue";
import HeaderView from "@/views/HeaderView.vue";
import paystack from "vue-paystack";

export default {
  components: {
    SideBar,
    HeaderView,
    paystack,
  },
  data() {
    return {
      paystackkey: "pk_test_6555651ecaf3d32f6c6a49dd81f875f06be5f332", //paystack public key
      user: "",
      channels: ["bank", "ussd", "bank_transfer"],
      transactions: [],
      facilities: "",
      plans: "",
      okay: false,
    };
  },
  computed: {
    reference() {
      let text = "";
      let possible = "0123456789";

      for (let i = 0; i < 11; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
  },
  beforeMount() {
    this.axios
      .get(`/api/users/${this.$route.params.id}`)
      .then((response) => {
        console.log(response);
        this.user = response.data.data;
        this.transactions = response.data.data.transactions;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    callback: function (response) {
      this.isLoading = true;
      axios
        .post(`/api/payment_link/verify`, {
          new_customer: 0,
          plan_id: this.user.latest_subscription.plan.id,
          reference: response.reference,
          user_id: this.user.id,
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$router.push(
            `/transaction-success/${response.data.data.reference}`
          );
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$router.push(`/transaction-failed`);
        });
    },
    close: function () {
      console.log("Payment closed");
    },
  },
  created() {
    this.getFacilities();
    this.getPlans();
  },
};
</script>
      
      
      <style scoped>
.btn-icon {
  @apply bg-transparent border border-primary rounded-md px-4 py-2;
}
.detail-key {
  @apply text-slate-500 font-normal w-1/2;
}
.detail-value {
  @apply text-primary font-normal  w-1/2;
}

.bio-key {
  @apply text-gray-600 font-thin my-3;
}
.bio-value {
  @apply font-medium my-3;
}
.biodata {
  @apply card w-full md:w-4/12;
}
.bioaccounts {
  @apply card w-full md:w-8/12;
}
</style>