<template>
  <div class="main-content relative">
    <SideBar />

    <div class="page-view bg-slate-100">
      <section class="flex justify-between w-full h-20 items-center shadow-xl">
        <div>
          <h2 class="text-2xl content-spacer">Add Plan</h2>
        </div>

        <div class="mr-10">
          <select name="" class="py-3 rounded">
            <option class="py-2">{{ $store.state.user.firstname }}</option>
            <option class="py-6">Settings</option>
          </select>
        </div>
      </section>

      <div class="page-container">
        <div class="form-card">
          <div class="">
            <div class="mb-6">
              <p class="balance-subtitle"></p>
            </div>
            <div class="flex flex-col space-y-3">
              <div class="form-row">
                <input
                  type="email"
                  class="form-control auth-form"
                  placeholder="Plan Name"
                  v-model="plan.name"
                />
              </div>

              <div class="form-row flex flex-col">
                <select
                  name=""
                  v-model="plan.frequency"
                  class="form-control form-select bg-white"
                >
                  <option class="py-3">Select Frequency</option>
                  <option
                    class="py-3"
                    v-for="freq in frequencies"
                    :key="freq.id"
                    :value="freq"
                  >
                    {{ freq.name }}
                  </option>
                  <!-- <option class="py-3">Daily</option>
                  <option class="py-3">Weekly</option>
                  <option class="py-3">Monthly</option>
                  <option class="py-3">Yearly</option> -->
                </select>
              </div>

              <div class="form-row">
                <input
                  type="number"
                  class="form-control auth-form"
                  placeholder="Max Persons"
                  max="10"
                  min="1"
                  v-model="plan.max_persons"
                />
              </div>

              <div class="form-row">
                <input
                  type="number"
                  class="form-control auth-form"
                  placeholder="Plan Amount"
                  v-model="plan.amount"
                />
              </div>

              <div class="form-row">
                <textarea
                  name=""
                  placeholder="Details of your Plan"
                  id=""
                  class="form-control auth-form"
                  v-model="plan.description"
                  rows="5"
                ></textarea>
              </div>

              <div class="form-row">
                <button
                  class="bg-primary px-6 rounded-lg py-4 text-white font-bold w-[90%] md:w-[100%] mt-8"
                  @click="addForm"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="list-card">
          <div class="">
            <div class="mb-6">
              <p class="balance-amount">Plans</p>
            </div>

            <div class="">
              <table
                class="w-full text-left border border-separate rounded border-slate-200"
                cellspacing="0"
              >
                <tbody>
                  <tr class="bg-gray-100">
                    <th class="table-header">Plan</th>

                    <th class="table-header">Amount</th>
                    <th class="table-header">Freq/Days</th>
                    <th class="table-header">Max Persons</th>
                    <th class="table-header">Action</th>
                  </tr>

                  <tr class="" v-for="plan in plans" :key="plan.id">
                    <td class="table-td">
                      {{ plan.name }}
                    </td>
                    <td class="table-td">
                      <div class="">
                        <span> N{{ plan.amount | numeral(0, 0) }}</span>
                      </div>
                    </td>
                    <td class="table-td">
                      {{ plan.frequency }} / {{ plan.days }}
                    </td>

                    <td class="table-td">
                      {{ plan.max_persons }}
                    </td>

                    <td class="table-td">
                      <button class="btn-sm">
                        edit <i class="las la-edit"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- End Responsive Table -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
  
  <script>
// import plansJson from "./plans.json";
import SideBar from "@/views/SideBar.vue";

export default {
  components: {
    SideBar,
  },
  data() {
    return {
      plan: {
        frequency: "Select Frequency",
      },
      frequencies: [
        {
          name: "Daily",
          days: 1,
        },
        {
          name: "Weekly",
          days: 7,
        },
        {
          name: "Monthly",
          days: 30,
        },
        {
          name: "Yearly",
          days: 365,
        },
      ],
      plans: [],
      response: "",
      okay: false,
    };
  },
  beforeMount() {},
  methods: {
    addForm() {
      this.axios
        .post(`/api/plans`, {
          hmo_id: this.$store.state.user.hmo.id,
          name: this.plan.name,
          amount: this.plan.amount,
          description: this.plan.description,
          max_persons: this.plan.max_persons,
          frequency: this.plan.frequency.name,
          days: this.plan.frequency.days,
        })
        .then((response) => {
          console.log(response);
          this.$toastr.s("Plan Added Successsfully!");
          this.plan = {};
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getPlans() {
      this.axios
        .get(`/api/plans/hmo/${this.$store.state.user.hmo.id}`)
        .then((response) => {
          console.log(response);
          this.plans = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    submitForm() {
      this.$router.push("/overview");
    },
  },
  created() {
    this.getPlans();
  },
};
</script>
  
  
  <style scoped>
.page-container {
  @apply flex flex-col md:flex-row content-spacer p-4 space-y-3 md:space-x-3;
}
.form-card{
  @apply card w-full md:w-1/3 ;
}
.list-card{
  @apply card w-full md:w-2/3 ;
}
.form-row {
  @apply my-2;
}

.auth-form {
  @apply placeholder:text-black w-[100%]  md:w-[100%] border border-gray-300;
}
.balance-amount {
  @apply font-bold text-3xl;
}
</style>