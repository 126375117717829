import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";
import AgentLogin from "../views/AgentLogin.vue";
import RegisterView from "../views/RegisterView.vue";
import OverView from "../views/HomeView.vue";
import AgentOverview from "../views/AgentOverview.vue";
import HealthFacilities from "../views/HealthFacilities.vue";
import HealthPlans from "../views/HealthPlans.vue";
import CustomersView from "../views/CustomersView.vue";
import AddPlan from "../views/AddPlan.vue";
import AddStaff from "../views/AddStaff.vue";
import AddTicket from "../views/AddTicket.vue";
import AddFacility from "../views/AddFacility.vue";
import AddCustomer from "../views/AddCustomer.vue";
import EditCustomer from "../views/EditCustomer.vue";
import CustomerTransactionsList from "../views/CustomerTransactionsList.vue";
import SingleCustomer from "../views/SingleCustomer.vue";
import PaymentURL from "../views/PaymentURL.vue";
import TransactionSuccess from "../views/TransactionSuccess.vue";
import TransactionFailed from "../views/TransactionFailed.vue";
import InquiriesView from "../views/InquiriesView.vue";
import HMOSettings from "../views/HMOSettings.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
  },
  {
    path: "/agent-login",
    name: "agentlogin",
    component: AgentLogin,
  },

  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/overview",
    name: "overview",
    component: OverView,
  },
  {
    path: "/agent-overview",
    name: "agentoverview",
    component: AgentOverview,
  },
  {
    path: "/wallet",
    name: "wallet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WalletView.vue"),
  },
  {
    path: "/health-plans",
    name: "HealthPlans",
    component: HealthPlans,
  },

  {
    path: "/facilities",
    name: "HealthFacilities",
    component: HealthFacilities,
  },

  {
    path: "/inquiries",
    name: "InquiriesView",
    component: InquiriesView,
  },

  {
    path: "/customers",
    name: "CustomersView",
    component: CustomersView,
    // redirect: '/patient-:id/records',
  },

  {
    path: "/manage-plans",
    name: "ManagePlans",
    component: AddPlan,
  },

  {
    path: "/manage-staff",
    name: "ManageStaff",
    component: AddStaff,
  },

  {
    path: "/add-ticket",
    name: "AddTicket",
    component: AddTicket,
  },
  {
    path: "/add-facility",
    name: "AddFacility",
    component: AddFacility,
  },
  {
    path: "/add-customer",
    name: "AddCustomer",
    component: AddCustomer,
  },

  {
    path: "/customer/:id",
    name: "SingleCustomer",
    component: SingleCustomer,
    redirect: '/customer/:id/transactions',

    children: [
      
      {
        path: "transactions",
        name: "CustomerTransactionsList",
        component: CustomerTransactionsList,
      },
      {
        path: "edit",
        name: "EditCustomer",
        component: EditCustomer,
      },
    ],
  },
  {
    path: "/pay/:payment_url",
    name: "PaymentURL",
    component: PaymentURL,
  },

  {
    path: "/transaction-success/:reference",
    name: "TransactionSuccess",
    component: TransactionSuccess,
  },
  {
    path: "/transaction-failed",
    name: "TransactionFailed",
    component: TransactionFailed,
  },
  {
    path: "/org-settings",
    name: "HMOSettings",
    component: HMOSettings,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
