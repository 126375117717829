<template>
  <div class="bg-gray-100">
    <div class="w-full bg-slate-100">
      <section
        class="flex flex-row space-x-2 justify-center w-full h-20 items-center shadow-xl"
      >
        <div>
          <i class="las la-check-circle la-3x"></i>
        </div>
        <div class="">
          <span class="text-2xl"> Thank you for the Purchase </span>
        </div>
      </section>

      <div
        class="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-x-6"
      >
        <div class="card mt-8 w-[90%] md:w-[40%]" id="printDiv" ref="printNow">
          <div class="">
            <h3 class="font-semibold text-xl text-center capitalize">
              Transaction Receipt
            </h3>
            <div class="flex flex-row my-6 justify-between">
              <div class="w-1/2">
                <p class="balance-title">Date</p>
                <p class="balance-title">Customer</p>
                <p class="balance-title">Agency</p>
                <p class="balance-title">Transaction ID</p>
                <p class="balance-title">Plan</p>
                <p class="balance-title">Expiry Date</p>
                <p class="balance-title">Status</p>
              </div>
              <div class="w-1/2">
                <p class="balance-subtitle">
                  {{ transaction.created_at | moment("d MMM Y, h:mma") }}
                </p>
                <p class="balance-subtitle">
                  {{ transaction.customer.full_name }}
                </p>

                <p class="balance-subtitle">
                  {{ transaction.subscription.plan.hmo.name }}
                </p>
                <p class="balance-subtitle">#{{ transaction.reference }}</p>
                <p class="balance-subtitle">
                  {{ transaction.subscription.plan.name }}
                </p>
                <p class="balance-subtitle">
                  {{ transaction.subscription.expired_at | moment("d MMM Y") }}
                </p>
                <p class="balance-subtitle">
                  {{ transaction.status }}
                </p>
              </div>
            </div>

            <hr />
            <div class="flex justify-between mt-4">
              <span class="font-bold text-lg">Total</span>
              <span class="font-bold text-lg">
                ₦{{ transaction.amount | numeral(0, 0) }}
              </span>
            </div>
          </div>
        </div>
        <!-- End of Card -->

        <div class="">
          <p>
            For enquiries Contact us at
            <a href="tel:+08023493849" class="text-primary font-semibold"
              >08023493849</a
            >
          </p>
          <p>
            or
            <a
              href="mailto:support@insucare.ng"
              class="text-primary font-semibold"
              >support@insucare.ng</a
            >
          </p>

          <button class="btn my-6 mx-auto" @click="printMe">
            <i class="las la-print"></i>
            Download Receipt
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
      
      
      
      <script>
import paystack from "vue-paystack";
// import SideBar from "@/views/SideBar.vue";

export default {
  components: {
    // SideBar,
    paystack,
  },
  data() {
    return {
      paystackkey: "pk_test_6555651ecaf3d32f6c6a49dd81f875f06be5f332", //paystack public key
      email: "faisalnas7@gmail.com",
      transaction: "",
      plans: [],
      response: "",
      hmo: "",
    };
  },
  computed: {},
  beforeMount() {},
  methods: {
    getTrx() {
      this.axios
        .get(`/api/transaction_reference/${this.$route.params.reference}`)
        .then((response) => {
          console.log(response);
          this.transaction = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    printMe() {
      var printContents = document.getElementById("printDiv").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
    submitForm() {
      this.$router.push("/overview");
    },
  },
  created() {
    this.getTrx();
  },
};
</script>
      
 <style scoped>
.form-row {
  @apply my-2;
}

.auth-form {
  @apply placeholder:text-slate-500 w-[100%]  md:w-[100%] border border-gray-300;
}

.auth-form-new {
  @apply placeholder:text-slate-500 w-full  md:w-1/2 border border-gray-300;
}

.balance-title {
  @apply text-primary  font-medium h-10;
}

.balance-subtitle {
  @apply font-light text-gray-600 h-10;
}
</style>