import { render, staticRenderFns } from "./SingleCustomer.vue?vue&type=template&id=bf2e3dee&scoped=true&"
import script from "./SingleCustomer.vue?vue&type=script&lang=js&"
export * from "./SingleCustomer.vue?vue&type=script&lang=js&"
import style0 from "./SingleCustomer.vue?vue&type=style&index=0&id=bf2e3dee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf2e3dee",
  null
  
)

export default component.exports