<template>
  <div id="app" class="font-sans">
    <router-view />

    <!-- <div class="vld-parent">
      <loading
        :active.sync="$store.state.loader.isLoading"
        loader="spinner"
        :color="$store.state.loader.color"
        :can-cancel="true"
        :is-full-page="$store.state.loader.fullPage"
      ></loading>
    </div> -->

    <section class="flex text-center w-full h-16 items-center bg-slate-100">
      <div class="mx-auto text-center">
        <h2 class="text-sm">Hayok Medicare ltd &copy;2024</h2>
      </div>
    </section>
  </div>
</template>


<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

/* nav {
  padding: 30px;
} */

/* nav a {
  font-weight: bold;
  color: #2c3e50;
} */

/* nav a.router-link-exact-active {
  color: #42b983;
} */

nav a.router-link-exact-active {
  @apply border w-[90%] flex items-center pl-2 rounded-lg py-2 bg-gray-50;
}
</style>
