

<template>
  <div class="flex flex-row">
    <div
      class="w-1/2 h-screen bg-no-repeat bg-cover"
      style="background-image: url('/reg_6.jpg')"
    ></div>
    <div class="flex w-1/2 text-center justify-center items-center h-screen bg-slate-100">
      <div class="mx-auto w-[80%]">
        <div class="mb-6">
          <img src="/logo.png" class="w-16 h-16 mx-auto mb-2" />

          <h2 class="text-3xl font-bold leading-5">Join Insucare</h2>
          <p class="balance-subtitle"></p>
        </div>

        <div class="flex flex-col space-y-3">
          <form action="" @submit.prevent="addHMO">
            <div class="form-row">
              <input
                type="text"
                required
                class="form-control auth-form"
                placeholder="First Name"
                v-model="auth_user.firstname"
              />

              <input
                type="text"
                required
                class="form-control auth-form"
                placeholder="Last Name"
                v-model="auth_user.lastname"
              />
            </div>

            <div class="form-row">
              <input
              required
                type="email"
                class="form-control auth-form"
                placeholder="Email"
                v-model="auth_user.email"
              />

              <input
              required
                type="text"
                class="form-control auth-form"
                placeholder="Phone Number"
                v-model="auth_user.phone_number"
              />
            </div>

            <div class="form-row">
              <input
              required
                type="text"
                class="form-control auth-form"
                placeholder="Agency Full Name"
                v-model="auth_user.hmo_name"
              />

              <input
              required
                type="text"
                class="form-control auth-form"
                placeholder="Agency Abbreviate Name"
                v-model="auth_user.abbr"
              />

             
            </div>


            <div class="form-row">
              <input
              required
                type="text"
                class="form-control auth-form"
                placeholder="Agency Website Link"
                v-model="auth_user.hmo_link"
              />

              <input
              required
                type="email"
                class="form-control auth-form"
                placeholder="Agency e-mail"
                v-model="auth_user.hmo_email"
              />

            </div>

            <div class="form-row">
              <input
              required
                type="text"
                class="form-control auth-form"
                placeholder="Agency Address"
                v-model="auth_user.hmo_address"
              />

              <input
              required
                type="password"
                class="form-control auth-form"
                placeholder="Password"
                v-model="auth_user.password"
              />
            </div>

            <div class="form-row">
              <button
                class="bg-primary px-6 rounded-lg py-3 text-white font-bold w-full mt-8"
                type="submit"
              >
                Get Started
              </button>
            </div>
          </form>
          <div class="form-row">
            <div class="flex space-x-3 justify-center">
              <div class="">
                <p class="text-lg">
                  By Signing up you agree to our

                  <span class="text-primary"> Terms of use</span>

                  and

                  <span class="text-primary"> Privacy Policy</span>
                </p>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="flex space-x-3 justify-center">
              <div class="">
                <p class="text-lg">Already have an account?</p>
              </div>
              <router-link to="/">
                <p class="text-primary text-lg">Log in</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  components: {},
  data() {
    return {
      auth_user: {
        password: "",
        email: "",
        firstname: "",
        lastname: "",
        phone_number: "",
        wallet_amount: 0.0,
        month_spent: 0.0,
        transactions: [],
      },
      response: "",
      okay: false,
    };
  },
  beforeMount() {},
  methods: {
    addHMO() {
      this.axios
        .post(`/api/auth/add-hmo`,{
          name : this.auth_user.hmo_name,
          email : this.auth_user.hmo_email,
          address : this.auth_user.hmo_address,
          is_government_owned : false,
        })
        .then((response) => {
          let hmoId = response.data.id;
          this.addStaff(hmoId)
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addStaff(hmoId) {
      this.axios
        .post(`/api/auth/register/staff`,{
          firstname : this.auth_user.firstname,
          lastname : this.auth_user.lastname,
          email : this.auth_user.email,
          phone_number : this.auth_user.phone_number,
          password : this.auth_user.password,
          is_admin : 1,
          hmo_id : hmoId,
        })
        .then((response) => {
          console.log(response);
          this.$router.push("/");

        })
        .catch((error) => {
          console.error(error);
        });
    },
    registerUser() {
      localStorage.setItem("user", JSON.stringify(this.auth_user));
      this.$router.push("/");
    },
  },
  created() {},
};
</script>

  
  <style scoped>
.form-row {
  @apply flex flex-row my-2 space-x-4 basis-4/5;
}

.auth-form {
  @apply placeholder:text-black w-full border border-gray-300;
}
</style>
