<template>
  <div class="main-content relative">
    <SideBar />

    <div class="page-view bg-slate-100">
      <section class="flex justify-between w-full h-20 items-center shadow-xl">
        <div>
          <h2 class="text-2xl content-spacer">Settings</h2>
        </div>
        <!-- ppp {{ hmo.is_government_owned }} -->

        <div class="mr-10">
          <select name="" class="py-3 rounded">
            <option class="py-2">{{ $store.state.user.firstname }}</option>
            <option class="py-6">Settings</option>
          </select>
        </div>
      </section>

      <div class="flex content-spacer p-4">
        <div class="card mx-auto w-[80%]">
          <div class="">
            <div class="mb-6">
              <p class="form-title">
                Agency Information 
              </p>
            </div>
            <div class="flex flex-col space-y-3">
              <div class="form-row">
                <input
                  type="text"
                  class="form-control auth-form"
                  placeholder="Agency Full Name"
                  v-model="hmo.name"
                />

                <input
                  type="text"
                  class="form-control auth-form"
                  placeholder="Agency Abbreviated Name"
                  v-model="hmo.abbreviated_name"
                />
              </div>

              <div class="form-row">
                <input
                  type="text"
                  class="form-control auth-form"
                  placeholder="Agency Phone Number"
                  v-model="hmo.phone_number"
                />

                <input
                  type="email"
                  class="form-control auth-form"
                  placeholder="Agency E-mail"
                  v-model="hmo.email"
                />
              </div>

              <div class="form-row">
                <input
                  type="text"
                  class="form-control auth-form"
                  placeholder="Office Address"
                  max="10"
                  min="1"
                  v-model="hmo.address"
                />

                <input
                  type="text"
                  class="form-control auth-form"
                  placeholder="Payment Link"
                  v-model="hmo.payment_url"
                />
              </div>

              <div class="form-row">
                <input
                  type="text"
                  class="form-control auth-form"
                  placeholder="Hayok ID"
                
                  v-model="hmo.hip_id"
                />

                <input
                  type="text"
                  disabled
                  class="form-control auth-form"
                  placeholder="Account Code"
                  v-model="hmo.sub_account_code"
                />
              </div>

            

              <div class="form-row">
                <button
                  class="bg-primary px-6 rounded-lg py-4 text-white font-bold w-[90%] md:w-[100%] mt-8"
                  @click="submitForm"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    
    
    <script>
// import plansJson from "./plans.json";
import SideBar from "@/views/SideBar.vue";

export default {
  components: {
    SideBar,
  },
  data() {
    return {
      plan: {
        frequency: "Select Frequency",
      },
      hmo: "",
      settings: "",
      plans: [],
      response: "",
      okay: false,
    };
  },
  beforeMount() {
    // this.hmo = this.$store.state.user.hmo;
  },
  methods: {
    getSettings() {
      this.axios
        .get(`/api/hmos/${this.$store.state.user.hmo_id}`)
        .then((response) => {
          console.log(response);
          this.hmo = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    submitForm() {
      this.axios
        .put(`/api/hmos/${this.hmo.id}`, {
          name: this.hmo.name,
          abbreviated_name: this.hmo.abbreviated_name,
          phone_number: this.hmo.phone_number,
          address: this.hmo.address,
          email: this.hmo.email,
          payment_url: this.hmo.payment_url,
          update_settings: true,
          // is_government_owned: true,

          //settings
        //   auto_sync_with_callback: this.settings.auto_sync_with_callback,
          users_callback_url: this.settings.users_callback_url,
          plans_callback_url: this.settings.plans_callback_url,
          facilities_callback_url: this.settings.facilities_callback_url,
          tickets_callback_url: this.settings.tickets_callback_url,
        })
        .then((response) => {
          console.log(response);
          this.$toastr.s("HMO Updated Successsfully!");
          this.plan = {};
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // submitForm() {
    //   this.$router.push("/overview");
    // },
  },
  created() {
    this.getSettings();
  },
};
</script>
    
    
    <style scoped>
.form-row {
  @apply my-2 flex flex-row space-x-4;
}

.auth-form {
  @apply placeholder:text-black w-[100%]  md:w-[100%] border border-gray-300;
}

.form-title {
  @apply text-primary text-2xl font-extralight;
}
</style>