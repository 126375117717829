<template>
  <div class="main-content">
    <SideBar />
    <div class="page-view">
      <HeaderView />

      <div class="content-spacer flex space-x-2 flex-col md:flex-row">
        <div class="flex flex-col md:flex-row card w-full">
          <div class="flex flex-col basis-3/5">
            <label class="font-bold">Plan Type </label>
            <select v-model="selected_plan" class="form-control form-select">
              <option
                class="py-2"
                v-for="plan in plans"
                :key="plan.id"
                :value="plan"
              >
                {{ plan.name }}
              </option>
            </select>
          </div>

          <div class="basis-2/5">
            <button
              class="flex justify-around bg-primary font-bold text-white py-4 rounded mt-8 px-6 w-full"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </span>

              See Customers
            </button>
          </div>
        </div>
      </div>

      <p class="font-thin text-xl content-spacer mt-3">Featured Customers</p>

      <div
        class="flex card content-spacer divide-x-2"
        v-for="cus in selected_plan.subscribers"
        v-bind:key="cus.id"
      >
        <div class="flex flex-col divide-y-2 basis-4/5">
          <div class="flex h-1/2 items-center justify-between">
            <div class="flex space-x-16">
              <div class="">
                <p class="font-bold text-xl">{{ cus.user.full_name }}</p>
                <p class="balance-subtitle text-lg">
                  {{ cus.plan.name }}
                </p>
              </div>
            </div>

            <div
              class="flex bg-violet-200 justify-end py-2 rounded-lg px-2 text-black font-thin mr-4"
            >
              {{ cus.user.phone_number }}
            </div>
          </div>

          <div class="flex h-1/2 space-x-28 items-center">
            <div class="flex flex-col">
              <p class="font-bold text-xl">{{ cus.plan.frequency }}</p>
              <p class="balance-subtitle text-lg">Max. Persons</p>
            </div>

            <div class="flex flex-col">
              <p class="font-bold text-xl">{{ cus.expired_at }}</p>
              <p class="balance-subtitle text-lg">Expiry</p>
            </div>

            <div class="flex flex-col">
              <p class="font-bold text-xl">{{ cus.user.facility.name }}</p>
              <p class="balance-subtitle text-lg">Health Facility</p>
            </div>
          </div>
        </div>
        <div class="flex flex-col basis-1/5 justify-center px-3 space-y-4">
          <p class="font-semibold text-4xl text-center">
            &#8358;{{ cus.plan.amount }}
          </p>
          <p class="balance-subtitle text-center">{{ cus.plan.frequency }}</p>
          <button class="bg-primary px-6 rounded py-2 text-white font-semibold">
            View this Plan
          </button>

          <button
            class="bg-white border border-primary px-6 rounded py-2 text-primary font-semibold"
          >
            View Customer
          </button>
        </div>
      </div>

      <!-- End of pLANS Lists -->
    </div>
    <BottomNav />
  </div>
</template>

<script>
// @ is an alias to /src
import SideBar from "@/views/SideBar.vue";
import BottomNav from "@/views/BottomNav.vue";
import HeaderView from "@/views/HeaderView.vue";

export default {
  name: "HomeView",
  components: {
    SideBar,
    BottomNav,
    HeaderView,
  },
  data() {
    return {
      selected_plan: "",
      plans: [],
    };
  },
  methods: {
    getPlans() {
      this.axios
        .get(`/api/plans/hmo/${this.$store.state.user.hmo_id}`)
        .then((response) => {
          console.log(response);
          this.plans = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  created() {
    this.getPlans();
  },
};
</script>
<style scoped>
.home-view {
  @apply col-span-6;
}

.balance-title {
  @apply text-primary text-xl font-extralight;
}
.balance-amount {
  @apply font-bold text-3xl;
}
.balance-subtitle {
  @apply font-extralight text-gray-600;
}
.form-select {
  @apply mx-3 py-4;
}
</style>

